<template>
  <div class="loginBox" :key="codeLogin">
    <van-nav-bar :border="false" @click-left="$parent.goBackAccount" @click-right="register" fixed placeholder>
      <template #left>
        <van-icon name="cross" size="24" />
      </template>
      <template #right>
        <van-button size="small" plain type="info" class="fs26">注册新账号</van-button>
      </template>
    </van-nav-bar>
    <Space :height="147"></Space>
    <div class="contentBox">
      <TextBox :font-size="44" color="title" :bold="5">登录</TextBox>
      <Space :height="12"></Space>
      <TextBox color="intro">输入您已注册的账号</TextBox>
      <Space :height="58"></Space>
      <van-field
        clearable
        v-model="phone"
        type="number"
        placeholder="手机号"
        class="border errorRight5"
        :error="!!phoneError"
        :error-message="phoneError"
        label-width="auto"
      >
        <template #label>
          <div @click="$refs.selectCountry.init()">+{{ countryCode }}</div>
        </template>
      </van-field>
      <Space :height="29"></Space>
      <van-field v-model="code" type="number" placeholder="验证码" class="border errorRight15" v-if="codeLogin">
        <template #button>
          <SendCode url="/v1/auth/sendLoginCode" :phone="phone" :area-code="countryCode"></SendCode>
        </template>
      </van-field>
      <van-field
        v-else
        v-model="password"
        :type="passwordType"
        placeholder="密码"
        class="border errorRight5"
        :right-icon="passwordIcon"
        @click-right-icon="switchPassword"
        :error="!!passwordError"
        :error-message="passwordError"
      >
      </van-field>
      <Space :height="34"></Space>
      <van-button size="small" plain type="info" class="right fs26" @click="switchLoginWay">
        切换{{ codeLogin ? '密码' : '验证码' }}登录
      </van-button>
      <Space :height="133"></Space>
      <div class="dfjcc">
        <van-button
          type="primary"
          block
          @click="submit"
          class="submitButton"
          :class="{ submitSuccess: submitSuccess }"
          :loading="$store.state.submitLoading"
        >
          <van-icon name="success" v-if="submitSuccess" />
          <span v-else>登录</span>
        </van-button>
      </div>
    </div>
    <SelectCountry ref="selectCountry" :callback="countryCallback"></SelectCountry>
  </div>
</template>
<script>
import qs from 'qs';
export default {
  name: '',
  // mixins: [],
  // components: {},
  // props: [],
  data() {
    return {
      // 验证码登录
      codeLogin: true,
      phone: '',
      phoneError: '',
      password: '',
      passwordError: '',
      code: '',
      passwordIcon: 'closed-eye',
      passwordType: 'password',
      submitSuccess: false,
      countryCode: 86,
    };
  },
  // created () {},
  // mounted() {},
  methods: {
    countryCallback(res) {
      this.countryCode = res.code;
    },
    register() {
      let params = '';
      if (this.$route.query) {
        params = `?${qs.stringify(this.$route.query)}`;
      }
      this.$router.push(`/account/register${params}`);
    },
    switchPassword() {
      if (this.passwordIcon === 'closed-eye') {
        this.passwordIcon = 'eye';
        this.passwordType = 'text';
      } else {
        this.passwordIcon = 'closed-eye';
        this.passwordType = 'password';
      }
    },
    switchLoginWay() {
      // 切换登录方式
      this.codeLogin = !this.codeLogin;
      this.password = '';
      this.code = '';
    },
    submit: _.debounce(function() {
      if (!this.phone) {
        this.phoneError = '手机号不能为空';
        return;
      }
      if (this.codeLogin) {
        if (!this.code) {
          this.$toast('验证码不能为空');
          return;
        }
      } else {
        if (!this.password) {
          this.passwordError = '密码不能为空';
          return;
        }
      }
      this.$store.commit('setSubmitLoading', true);
      this.$http('post', '/v1/auth/login', {
        phone: this.phone,
        password: this.password,
        code: this.code,
        areaCode: this.countryCode,
      })
        .then(res => {
          this.submitSuccess = true;
          this.$$localStorage.setItem('token', res.data.token);
          this.$$localStorage.setItem('tokenExpireTime', this.$dayjs(res.data.expireTime).valueOf());
          setTimeout(() => {
            this.$router.push('/home');
            // if (this.$route.query.redirect) {
            //   this.$router.push(this.$route.query.redirect);
            // } else {
            //   this.$router.push('/home');
            // }
          }, 1000);
        })
        .all(() => {
          this.$store.commit('setSubmitLoading', false);
        });
    }),
  },
  // watch: {},
  // computed: {},
  // filters: {},
  // beforeDestroy () {},
};
</script>
<style scoped lang="less">
.submitSuccess {
  transition: all 0.2s;
  border-radius: 50%;
  width: 90px;
  height: 90px;
}
</style>
